import setsNotify from '@/mixins/sets-notify.mixin'

// initial state
const state = () => ({
  message: ''
})

// getters
const getters = {
}
// actions
const actions = {
  addMessage ({ commit }, message) {
    commit('setMessage', message)
  },
  showMessage ({ commit }) {
    if (state.message) {
      setsNotify.methods.setNotifyDanger(state.message)
    }
    commit('clearMessage')
  }
}

// mutations
const mutations = {
  setMessage (state, message) {
    state.message = message
  },
  clearMessage (state) {
    state.message = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
