import axios from 'axios'
import localforage from 'localforage'
import { setup } from 'axios-cache-adapter'
import Vue from 'vue'
import router from '../router/index'
import store from '../store/index'
import { v4 as uuidv4 } from 'uuid'

const shadowBan = new Date()

const $axios = setup({
  baseURL: process.env.VUE_APP_API_URL,
  cache: {
    maxAge: 300000, // 5 min
    readHeaders: false,
    store: localforage.createInstance({ name: 'cartolol.com.br' }),
    clearOnStale: false,
    clearOnError: true,
    invalidate: async (config, request) => {
      const method = request.method.toLowerCase()
      if (config.exclude.methods.includes(method)) {
        await config.store.clear()
      }
    },
    exclude: {
      paths: [/\/user\/\d+\//],
      query: false
    }
  }
})

$axios.defaults.withCredentials = true

$axios.interceptors.request.use(config => {
  config.metadata = { startTime: new Date() }
  if (config.url.includes('user/logout')) {
    return config
  }
  var now = new Date().getTime()
  if (shadowBan.getTime() > now) {
    var seconds = Math.ceil((shadowBan.getTime() - now) / (1000.0))
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) => cancel(seconds))
    }
  }
  return config
})

$axios.interceptors.response.use((response) => {
  response.config.metadata.endTime = new Date()
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime
  if (window.gtag !== undefined) {
    window.gtag('event', 'timing_complete', {
      name: `${response.config.method}:${response.config.url}`,
      value: response.duration,
      event_category: 'API Request',
      event_label: response.status
    })
    window.gtag('event', `${response.config.method}:${response.config.url}`, {
      value: response.headers['content-length'],
      event_category: 'Bandwidth',
      event_label: response.status
    })
  }
  return response
}, async function (error) {
  if (error.response && error.response.status === 401) {
    if (error.config.url.includes('token/refresh/')) {
      store.dispatch('auth/doLogout')
      const vm = new Vue()
      router.push('/login', () => {
        const h = vm.$createElement
        // Create a ID with a incremented count
        const id = `my-toast-${uuidv4()}`

        // Create the custom close button
        const $closeButton = h(
          'b-button-close',
          {
            on: { click: () => vm.$bvToast.hide(id) }
          }
        )
        vm.$bvToast.toast(['Você precisa estar logado para realizar esta ação!', $closeButton], {
          id: id,
          toaster: 'b-toaster-top-full',
          solid: true,
          appendToast: true,
          noCloseButton: true,
          variant: 'danger'
        })
      })
    } else {
      const resp = await $axios.post('token/refresh/')
      if (resp.status === 200) {
        window.localStorage.setItem('user', JSON.stringify(resp.data.user))
        store.dispatch('badge/showBadges', resp.data.user.badges)
        return await $axios.request(error.config)
      }
    }
  } else if (error.response && error.response.status === 429) {
    const retryAfter = /\d+/.exec(JSON.stringify(error.response.data))[0]
    shadowBan.setTime(new Date().getTime() + (retryAfter * 1000))
    throw new axios.Cancel(retryAfter)
  }
  return Promise.reject(error)
})

const service = $axios

export { service }
