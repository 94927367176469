<template>
  <div class="bg-dark border-menu" id="container-menu">
    <b-container fluid="xl" class="px-md-5 px-xl-0">
      <b-row class="">
        <b-col class="">
          <b-navbar
            toggleable="md"
            type="dark"
            variant="dark"
            class="py-3 px-0"
          >
            <b-navbar-toggle
              target="nav-collapse"
              class="mr-2"
            ></b-navbar-toggle>
            <b-navbar-brand to="/" class="mr-auto">
              <b-img
                :src="require('@/assets/img/logo.png')"
                fluid
                alt="Logo Cartolol"
              ></b-img>
            </b-navbar-brand>
            <AppMenuUsuario class="d-flex d-md-none"></AppMenuUsuario>
            <b-collapse id="nav-collapse" is-nav>
              <AppMenu class="d-md-none d-lg-flex"></AppMenu>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="d-flex justify-content-end" v-if="!isLoggedIn">
                <b-nav-item
                  to="/cadastro/"
                  class="btn py-0 px-2 mx-md-2 btn-1 mt-3 mt-md-0"
                >
                  <b-img
                    :src="require('@/assets/icons/broken/edit.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  REGISTRO
                </b-nav-item>
                <b-nav-item
                  to="/login/"
                  class="btn py-0 px-2 mx-md-2 btn-2 my-3 my-md-0"
                >
                  <b-img
                    :src="require('@/assets/icons/broken/login.svg')"
                    fluid
                    alt=""
                    class="icon-black btn-img mr-1"
                  ></b-img>
                  LOGIN
                </b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto d-none d-md-flex" v-else>
                <AppMenuUsuario class=""></AppMenuUsuario>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row class="d-md-block d-lg-none">
        <b-col class="">
          <b-navbar
            type="dark"
            variant="dark"
            class="border-menu py-3 py-3 px-0"
          >
            <b-collapse is-nav class="show">
              <AppMenu class="d-none d-md-flex"></AppMenu>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto mr-auto mr-md-0 w-100 w-md-auto">
                <b-nav-item
                  to="/jogueagora"
                  class="btn btn-3 py-0 px-2 mx-md-2 text-dark w-100 w-md-auto"
                >
                  <b-img
                    :src="require('@/assets/icons/broken/game.svg')"
                    fluid
                    alt=""
                    class="icon-dark btn-img mr-1"
                  ></b-img>
                  JOGUE AGORA
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
    <AppCookiePolicy/>
    <AppCookiePolicyBanner/>
    <AppRecaptchaModal/>
  </div>
</template>

<script>
import AppMenu from '@/components/AppMenu'
import AppMenuUsuario from '@/components/AppMenuUsuario'
import setsNotify from '../mixins/sets-notify.mixin'
import AppCookiePolicy from '@/components/modal/AppCookiePolicy.vue'
import AppCookiePolicyBanner from '@/components/modal/AppCookiePolicyBanner.vue'
import AppRecaptchaModal from '@/components/modal/AppRecaptchaModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AppNavbar',
  components: {
    AppMenu,
    AppCookiePolicy,
    AppCookiePolicyBanner,
    AppRecaptchaModal,
    AppMenuUsuario
  },
  mixins: [
    setsNotify
  ],
  data: () => ({
    loading: true
  }),
  mounted () {
    if (!window.__PRERENDER_INJECTED) {
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn'])
  }
}
</script>
