<template>
  <app-modal
    title="AVISO"
    id="recaptcha-settings"
  >
    <template #content>
      <b-row class="mx-3">
        <p class="text-justify">Este serviço faz uso da funcionalida do Google Recaptcha. Para usar este serviço, é necessário que você aceite a utilização de cookies do Google Recaptcha (Classe de funcionalidade de nosso menu de 'Redefinir Cookies'). Você deseja aceitar a utilização de cookies do Google Recaptcha?</p>
      </b-row>
      <b-row align-h="center">
        <b-button class="btn-default-secundary" @click="cookieSettings">Redefinir Cookies</b-button>
        <b-button class="btn-default ml-3" @click="accept" >Aceitar</b-button>
      </b-row>
    </template>
  </app-modal>
</template>

<script>
export default {
  name: 'app-recaptcha-modal',
  components: {
    AppModal: () => import('@/components/AppModal')
  },
  methods: {
    cookieSettings () {
      this.$bvModal.hide('recaptcha-settings')
      this.$bvModal.show('cookie-policy-settings')
    },
    accept () {
      const cookieConsent = localStorage.getItem('cookieConsent') ? JSON.parse(localStorage.getItem('cookieConsent')) : { performance: false }
      window.dispatchEvent(new CustomEvent('cookie-consent-localstorage-changed', {
        detail: {
          storage: {
            lastModified: null,
            necessary: true,
            functional: true,
            performance: cookieConsent.performance
          }
        }
      }))
      this.$bvModal.hide('recaptcha-settings')
    }
  }
}
</script>
