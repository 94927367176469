export default {
  methods: {
    toLongDateTime (date) {
      const dataLocal = new Date(date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })
      return dataLocal
    },
    toDate (date) {
      const dataLocal = new Date(date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })
      return dataLocal
    },
    toShortDate (date) {
      return new Date(date).toLocaleDateString('pt-BR', { month: 'short', day: 'numeric', timeZone: 'UTC' }).replace(' de ', '/').replace('.', '')
    },
    toTime (date) {
      const horaLocal = new Date(date).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
      return horaLocal
    },
    toTimestamp (date) {
      return new Date(date).getTime()
    },
    toPoolDate (date) {
      return new Date(date).toLocaleDateString('pt-BR', { month: 'short', day: '2-digit', weekday: 'long', timeZone: 'UTC' }).replace(' de ', ' ').replace('.', '').replace(',', ' -')
    }
  },
  computed: {
    today () {
      return new Date().toISOString().split('T')[0]
    }
  }
}
