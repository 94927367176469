import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import badge from '@/store/modules/badge'
import message from '@/store/modules/message'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    badge,
    message
  }
})
