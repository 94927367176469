import { service } from '@/boot/axios'
import { statusResponse } from '@/services/responseService'

// initial state
const state = () => ({
  status: '',
  user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null
})

// getters
const getters = {
  isLoggedIn: state => !!state.user,
  getUser: state => state.user
}

// actions
const actions = {
  async doLogin ({ commit, dispatch }, user) {
    try {
      commit('authRequest')
      const resp = await service.post('token/', user)
      window.localStorage.setItem('user', JSON.stringify(resp.data.user))
      dispatch('badge/showBadges', resp.data.user.badges, { root: true })
      commit('authSuccess', resp.data.user)
      if (user.savePassword) {
        return resp.data.user
      }
      return resp.data.user
    } catch (error) {
      if (error instanceof DOMException && (
        // everything except Firefox
        error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === 'QuotaExceededError' ||
        // Firefox
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
        if (window.localStorage) {
          window.localStorage.clear()
        }
        throw new Error('Parece que você esta com pouco espaço de armazenamento, tente logar novamente, se o error persistir tente liberar algum espaço.')
      }
      commit('authError')
      await service.post('user/logout/')
      window.localStorage.removeItem('user')
      throw statusResponse(error)
    }
  },
  async doLogout ({ commit }) {
    commit('logout')
    await service.post('user/logout/')
    await service.cache.clear()
    window.localStorage.removeItem('user')
  }
}

// mutations
const mutations = {
  authRequest (state) {
    state.status = 'loading'
  },
  authSuccess (state, user) {
    state.status = 'success'
    state.user = user
  },
  authError (state) {
    state.status = 'error'
  },
  logout (state) {
    state.status = ''
    state.user = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
