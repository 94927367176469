<template>
  <div>
    <b-toast id="cookie-policy-banner" body-class="bg-azul-escuro" toaster="b-toaster-top-full" solid no-auto-hide no-close-button>
      <b-row class="mt-3 mx-2 mb-5">
        Usamos cookies para armazenar informações sobre como você usa o nosso site e as páginas que visita. Tudo para tornar sua experiência a mais agradável possível. Para entender os tipos de cookies que utilizamos e customizá-los, clique em 'Definir Cookies'. Ao clicar em 'Aceitar', você consente com a utilização de cookies.
      </b-row>
      <b-row align-h="end">
        <b-button class="mx-2 btn-default-secundary btn-2" @click="cookieSetting" >Definir Cookies</b-button>
        <b-button class="mr-2 mr-sm-3 btn-3" @click="acceptCookies">Aceitar</b-button>
      </b-row>
    </b-toast>
    <div class="cookie-policy-buttom btn-3" v-if="hasUserConsented">
      <b-input-group size="lg">
        <b-button class="btn-3" squared style="width: 200px" @click="cookieSetting">Redefinir Cookies</b-button>
        <b-input-group-append>
          <img :src="require('@/assets/img/cookie.png')"/>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { load } from 'recaptcha-v3'
import { mapGetters } from 'vuex'
import setsNotify from '@/mixins/sets-notify.mixin'
export default {
  name: 'AppCookiePolicyBanner',
  mixins: [setsNotify],
  data: () => ({
    isSettingsVisible: false,
    lastModifed: '2021-09-01',
    consent: localStorage.getItem('cookieConsent') ? JSON.parse(localStorage.getItem('cookieConsent')) : null
  }),
  mounted () {
    this.consent = localStorage.getItem('cookieConsent') ? JSON.parse(localStorage.getItem('cookieConsent')) : null
    if (!window.__PRERENDER_INJECTED) {
      if (!(this.consent && this.consent.lastModifed === this.lastModified)) {
        this.$bvToast.show('cookie-policy-banner')
      }
    }
    window.addEventListener('cookie-consent-localstorage-changed', (event) => {
      this.consent = event.detail.storage
      this.consent.lastModified = this.lastModifed
      let success = false
      try {
        localStorage.setItem('cookieConsent', JSON.stringify(this.consent))
        success = true
      } catch (error) {
        if (localStorage) {
          localStorage.clear()
        }
      }
      if (!success) {
        try {
          localStorage.setItem('cookieConsent', JSON.stringify(this.consent))
          success = true
        } catch (error) {
          this.setNotifyDanger('Parece que você esta com pouco espaço de armazenamento, tente liberar algum espaço.')
        }
      }
      if (this.consent.performance) {
        window.gtag('consent', 'update', {
          analytics_storage: 'granted'
        })
      } else {
        window.gtag('consent', 'update', {
          analytics_storage: 'denied'
        })
      }
      if (this.consent.functional) {
        this.startRecaptch()
      }
      if (this.isLoggedIn) {
        this.$gtag.config({
          user_id: this.getUser.id
        })
      }
      this.$gtag.optIn()
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'cookie-policy-settings') {
        if (!(this.consent && this.consent.lastModifed === this.lastModified)) {
          this.$bvToast.show('cookie-policy-banner')
        }
      }
    })
    if (this.consent && this.consent.performance) {
      this.$gtag.query('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'granted',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied'
      })
    } else {
      this.$gtag.query('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied'
      })
    }
    this.$gtag.optIn()
    if (this.consent && this.consent.functional) {
      this.startRecaptch()
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'getUser']),
    hasUserConsented () {
      return this.consent && this.consent.lastModifed === this.lastModified
    },
    showCooieBanner () {
      if (this.isSettingsVisible) {
        return false
      }
      return !(this.consent && this.consent.lastModifed === this.lastModified)
    }
  },
  methods: {
    cookieSetting () {
      this.$bvToast.hide('cookie-policy-banner')
      this.$bvModal.show('cookie-policy-settings')
    },
    acceptCookies () {
      window.dispatchEvent(new CustomEvent('cookie-consent-localstorage-changed', {
        detail: {
          storage: {
            lastModified: null,
            necessary: true,
            functional: true,
            performance: true
          }
        }
      }))
      this.$bvToast.hide('cookie-policy-banner')
    },
    async startRecaptch () {
      await load(process.env.VUE_APP_RECAPTCHA_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', {
        explicitRenderParameters: {
          badge: 'bottomright'
        }
      })
    }
  }
}
</script>

<style>
@keyframes hide-cookie-buttom {
  0% {left: 4px;}
  70% {left: 4px;}
  100% {left: -200px;}
}

.bg-azul-escuro {
  background-color: #13151b;
  border: solid 1px #dcfc04;
}
.cookie-policy-buttom:hover {
  left: 4px;
}
.cookie-policy-buttom {
  z-index: 99999999;
  width: 256px;
  height: 50px;
  display: block;
  transition: left 0.8s ease 0s;
  position: fixed;
  bottom: 0px;
  left: -200px;
  box-shadow: #dcfc04 0px 0px 5px;
  border-radius: 2px;
  overflow: hidden;
  animation-name: hide-cookie-buttom;
  animation-duration: 2.8s;
}

</style>
