<template>
  <b-navbar-nav class="mx-auto">
    <b-nav-item to="/" :active="activeRouter === '/'">Início</b-nav-item>
    <b-nav-item to="/fantasy/" :active="activeRouter === '/fantasy/'" v-if="isLoggedIn">Fantasy</b-nav-item>
    <b-nav-item to="/bolao/" :active="activeRouter === '/bolao/'" v-if="isLoggedIn">Bolão</b-nav-item>
    <!-- <b-nav-item to="/como-funciona/" :active="activeRouter === '/como-funciona/'">Como funciona?</b-nav-item> -->
    <!-- <b-nav-item to="/noticias/" :active="activeRouter === '/noticias/'">Notícias</b-nav-item> -->
    <b-nav-item to="/contato/" :active="activeRouter === '/contato/'">Contato</b-nav-item>
  </b-navbar-nav>
</template>
<style>
.navbar-brand img{
  width: 150px;
}
@media (max-width: 767px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
    .conteudo-menu{
        margin-left: auto;

    }
}

@media (min-width: 1200px) {
}

</style>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppMenu',
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    activeRouter () {
      return this.$route.path
    }
  }
}
</script>
