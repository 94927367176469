<template>
  <b-container fluid="xl" class="px-5 px-xl-0 footer py-5">
    <b-row>
      <b-col
        class="text-3 col-12 col-md-5 col-xl-4 text-center text-md-left py-3"
      >
        <b-img
          :src="require('@/assets/img/logo.png')"
          fluid
          alt="Logo Cartolol"
          class="mr-md-3 d-inline-block pb-3 pb-md-0"
        ></b-img>
        <p class="pb-3 pb-md-0 mb-0 text-center">@2022 CartoLOL</p>
      </b-col>
      <b-col
        class="text-center col-12 col-md d-xl-block py-3 align-self-center"
      >
        <b-link class="text-3 px-3" to="/"> Início </b-link
        ><br class="d-xl-none" />
        <b-link class="text-3 px-3" to="/fantasy"> Fantasy </b-link
        ><br class="d-xl-none" />
        <b-link class="text-3 px-3" to="/politica">
          Política de Privacidade </b-link
        ><br class="d-xl-none" />
        <b-link class="text-3 px-3" to="/termos"> Termos de Uso </b-link
        ><br class="d-xl-none" />
        <b-link class="text-3 px-3" to="/contato"> Contato </b-link>
      </b-col>
      <b-col class="col-12 text-center text-3 dev pt-5 align-self-center mt-5">
        <small>
          Desenvolvido por:
          <b-link
            href="https://pauloviegas.com.br/"
            target="_blank"
            class="text-white"
            >Paulo Viegas</b-link
          >,
          <b-link
            href="https://github.com/igormaraujo/"
            target="_blank"
            class="text-white"
            >Igor Araújo</b-link
          >,
          <b-link
            href="https://github.com/fabriciopa"
            target="_blank"
            class="text-white"
            >Fabrício Araújo</b-link
          >
          e
          <b-link
            href="https://solo.to/hayato.eth"
            target="_blank"
            class="text-white"
            >Vinicius Rocha</b-link
          >
        </small>
      </b-col>
    </b-row>

    <!-- <AppBadgeModal /> -->
  </b-container>
</template>

<script>
// import AppBadgeModal from '@/components/modal/AppBadgeModal.vue'
export default {
  name: 'AppFooter',
  // components: { AppBadgeModal },
  data: () => ({
    version: process.env.APP_VERSION
  })
}
</script>

<style scoped>
</style>
