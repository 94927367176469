<template>
  <b-dropdown class="dropdown-user" right>
    <template #button-content>
      <div class="media">
        <div class="media-aside align-self-center">
          <div class="container-avatar">
            <b-avatar
              class="avatar"
              :src="userImage"
              :text="fisrtLetter"
            ></b-avatar>
          </div>
        </div>
        <div class="media-body align-self-center mr-2 mr-md-4">
          <h6 class="my-0 d-none d-md-block">{{ fisrtName }}</h6>
        </div>
      </div>
    </template>
    <b-dropdown-item :to="{path: '/perfil/'}">Meu Perfil</b-dropdown-item>
    <b-dropdown-item :to="{name: 'Change Profile'}"> Alterar Perfil</b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <!-- <b-dropdown-item href="https://admin.cartolol.com.br" target="blank" v-if="hasViewDashboardPermission">Sistema</b-dropdown-item> -->
    <b-dropdown-item @click="logout()">Sair</b-dropdown-item>
  </b-dropdown>
</template>
<style>
.dropdown-user .btn-secondary{
    background-color: transparent;
    border-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
}
.dropdown-user .dropdown-toggle::after{
    position: absolute;
    right: 10px;
    top: 25px;
}
@media (max-width: 767px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MenuUsuario',
  computed: {
    ...mapGetters('auth', ['getUser']),
    userImage () {
      if (this.getUser === null) {
        return null
      }
      return this.getUser.image
    },
    fisrtName () {
      if (this.getUser === null) {
        return ''
      }
      return this.getUser.name.split(' ')[0].trim()
    },
    fisrtLetter () {
      return this.fisrtName.charAt(0).toUpperCase()
    },
    hasViewDashboardPermission () {
      if (this.getUser === null) {
        return false
      }
      return this.getUser.permissions.includes('dashboard.view_dashboard')
    }
  },
  methods: {
    ...mapActions('auth', ['doLogout']),
    logout () {
      this.doLogout()
        .then(() => {
          if (this.$router.currentRoute.meta.requiresAuth) {
            this.$router.push({ name: 'Home' })
          }
          if (this.$gtag) {
            this.$gtag.event('Deslogar', { event_category: 'Token' })
          }
        })
        .catch(error => {
          this.setNotifyDanger(`Não foi possível realizar o logout. Error: ${error}`)
        })
    }
  }
}
</script>
