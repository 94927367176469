<template>
  <div>
    <section class="s1">
      <b-container fluid="xl" class="px-md-5 px-xl-0 container-home1">
        <b-row>
          <div class="col-12 col-xl-9 text-center text-xl-left pt-5 pt-xl-0">
            <h3 class="ff-chaney mb-4">
              Entre no <span class="text-1">Maior Fantasy</span> de League of
              Legends do <span class="text-1">Brasil</span>
            </h3>
          </div>
          <div class="col-12 col-xl-8 text-center text-xl-left">
            <p class="text-1 text-uppercase mb-4">
              Seja o melhor jogador e ganhe prêmios!
            </p>
            <h4 class="text-3">
              Monte seu melhor time, acompanhe os jogos e ganhe premiações
              incriveis!
            </h4>
          </div>
          <div class="col-12 col-xl-8 pt-5 text-center text-xl-left">
            <!-- <b-button to="/como-funciona/" class="btn-1 py-3 px-5 m-2">
              <b-img
                :src="require('../assets/icons/broken/teacher.svg')"
                fluid
                alt=""
                class="icon-white btn-img mr-1"
              ></b-img>
              COMO FUNCIONA?
            </b-button> -->
            <b-button to="/fantasy/" class="btn-3 py-3 px-5 m-2">
              <b-img
                :src="require('../assets/icons/broken/game.svg')"
                fluid
                alt=""
                class="icon-dark btn-img mr-1"
              ></b-img>
              JOGUE AGORA
            </b-button>
          </div>
        </b-row>
      </b-container>
      <b-container fluid class="container-limit">
        <b-row>
          <div class="col-12 text-right px-0">
            <b-img
              :src="require('../assets/img/home1.png')"
              fluid
              alt=""
              class="d-none d-xl-inline-block"
            ></b-img>
            <b-img
              :src="require('../assets/img/home1_md.png')"
              fluid
              alt=""
              class="d-xl-none"
            ></b-img>
          </div>
        </b-row>
      </b-container>
    </section>
    <section class="s2">
      <b-container fluid="xl" class="px-md-5 px-xl-0 py-5">
        <b-row>
          <b-col>
            <h3 class="ff-chaney py-5 text-center">
              É muito fácil jogar no <span class="text-1">cartolol</span>
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-md-6 col-lg-4 py-3">
            <div class="card text-center py-5 px-4">
              <p class="pb-5">
                <b-img
                  :src="require('../assets/img/home2.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </p>
              <h5 class="mb-3">Monte seu <span class="text-1">Time</span></h5>
              <p>
                É necessário que se cadastre na plataforma para montar seu time.
              </p>
            </div>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-4 py-3">
            <div class="card text-center py-5 px-4">
              <p class="pb-5">
                <b-img
                  :src="require('../assets/img/home3.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </p>
              <h5 class="mb-3">
                Acompanhe seus <span class="text-1">Jogadores</span>
              </h5>
              <p>
                Veja a perfomance de seus jogadores em cada rodada e continue
                evoluindo!
              </p>
            </div>
          </b-col>
          <b-col class="col-12 col-md-6 offset-md-3 offset-lg-0 col-lg-4 py-3">
            <div class="card text-center py-5 px-4">
              <p class="pb-5">
                <b-img
                  :src="require('../assets/img/home3.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </p>
              <h5 class="mb-3">
                Ganhe <span class="text-1">Prêmios Incríveis</span>
              </h5>
              <p>
                Acumule pontos, suba de posição no ranking e ganhe prêmios
                incríveis!
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="py-5 text-center">
            <b-button to="/fantasy/" class="btn-3 py-3 px-5 m-2">
              <b-img
                :src="require('../assets/icons/broken/people.svg')"
                fluid
                alt=""
                class="icon-dark btn-img mr-1"
              ></b-img>
              <strong>MONTE SEU TIME</strong>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="s3">
      <b-container fluid="xl" class="px-md-5 px-xl-0 py-5">
        <b-row>
          <b-col>
            <h3 class="ff-chaney py-5 text-center">
              monte seu time <span class="text-1">em qualquer lugar</span>
            </h3>
            <p>
              <b-img
                :src="require('../assets/img/home5.png')"
                fluid
                alt=""
                class=""
              ></b-img>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="py-5 text-center">
            <b-button to="/fantasy/" class="btn-3 py-3 px-5 m-2">
              <b-img
                :src="require('../assets/icons/broken/game.svg')"
                fluid
                alt=""
                class="icon-dark btn-img mr-1"
              ></b-img>
              <strong>JOGUE AGORA</strong>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- <section class="s4">
      <b-container fluid="xl" class="px-md-5 px-xl-0 py-5">
        <b-row>
          <b-col class="col-12 col-lg-9">
            <h3 class="ff-chaney py-5 text-center text-lg-left">
              Últimas <span class="text-1">novidades</span>
            </h3>
          </b-col>
          <b-col
            class="col-4 offset-4 offset-lg-0 col-lg-3 align-self-center d-none d-md-block"
          >
            <b-button to="/noticias" class="btn-1 py-3 px-3 m-2 d-block">
              <b-img
                :src="require('../assets/icons/broken/add-square.svg')"
                fluid
                alt=""
                class="icon-white btn-img mr-1"
              ></b-img>
              VEJA MAIS
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-overlay :show="failed" no-wrap bg-color="#152233">
            <template #overlay>
              <div class="text-center">
                <b-icon icon="exclamation-triangle-fill" font-scale="3" variant="danger"></b-icon>
                <p>Houve um problema ao carregar a pagina:</p>
                <p>{{error_message}}</p>
                <b-button
                  class="btn-default"
                  size="sm"
                  @click="consultaNews"
                >
                  Tentar Novamente
                </b-button>
              </div>
            </template>
          </b-overlay>
          <b-col v-for="(noticia, index) in news" :key="index" class="col-12 col-md-6 col-xl-4 py-3">
            <div class="card-noticia py-3 px-3">
              <p class="img">
                <b-link :to="{name: 'News_id', params: { id: noticia.id }}">
                  <div
                    class="img-content"
                    :style="{
                      'background-image':
                        'url(' + noticia.image + ')'
                    }"
                  ></div>
                </b-link>
              </p>
              <p>
                <b-button
                  :to="{name: 'News_id', params: { id: noticia.id }}"
                  size="sm"
                  class="btn-3 py-1 px-2 m-2"
                >
                  {{noticia.label}}
                </b-button>
              </p>
              <h5>
                {{ noticia.summary}}
              </h5>
              <p class="py-2">
                <small>
                  <b-img
                    :src="require('../assets/icons/bold/clock.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img>
                  {{ noticia.description }} <span class="text-3">de leitura</span>
                </small>
              </p>
              <div>
                <b-media class="pb-2">
                  <template #aside>
                    <div class="container-avatar">
                      <b-avatar :image="noticia.author_image" :text="noticia.author.substring(0, 1)" size="40" />
                    </div>
                  </template>

                  <h6 class="my-0">{{ noticia.author }}</h6>
                  <p class="text-3">
                    <small>{{ toLongDateTime(noticia.date) }}</small>
                  </p>
                </b-media>
              </div>
              <b-row>
                <b-col class="text-center col-12 col-md-5 pt-2">
                  <b-link
                    :to="{name: 'News_id', params: { id: noticia.id }}"
                    class="py-3 text-white d-block"
                  >
                    <b-img
                      :src="require('../assets/icons/broken/book.svg')"
                      fluid
                      alt=""
                      class="icon-white btn-img mr-1"
                    ></b-img>
                    LER AGORA
                  </b-link>
                </b-col>
                <b-col class="text-center col-12 col-md-7 pt-2">
                  <b-link
                    :to="{name: 'News_id', params: { id: noticia.id }}"
                    class="py-3 text-white d-block"
                  >
                    <b-img
                      :src="require('../assets/icons/broken/export.svg')"
                      fluid
                      alt=""
                      class="icon-white btn-img mr-1"
                    ></b-img>
                    COMPARTILHAR
                  </b-link>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 align-self-center d-md-none">
            <b-button to="/noticias" class="btn-1 py-3 px-3 m-2 d-block">
              <b-img
                :src="require('../assets/icons/broken/add-square.svg')"
                fluid
                alt=""
                class="icon-white btn-img mr-1"
              ></b-img>
              VEJA MAIS
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
  </div>
</template>

<script>
import NoticeService from '@/services/base'
import dataFormat from '@/mixins/data-format.mixin'
import { mapActions } from 'vuex'

export default {
  name: 'Home',
  mixins: [dataFormat],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Home',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data () {
    return {
      newService: new NoticeService('news/'),
      news: [],
      loading: true,
      failed: false,
      error_message: ''
    }
  },
  mounted () {
    this.showMessage()
    if (!window.__PRERENDER_INJECTED) {
      // this.consultaNews()
    }
  },
  methods: {
    ...mapActions('message', ['showMessage']),
    async consultaNews () {
      this.loading = true
      this.failed = false
      try {
        const { data } = await this.newService.list()
        this.news = data.data.slice(0, 4)
        this.loading = false
      } catch (error) {
        this.failed = true
        this.error_message = error.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .s1 {
    position: relative;
  }

  .s2 {
    background-color: #161921;
    border-top: 1px solid #2f323b;
    border-bottom: 1px solid #2f323b;
  }

  .s4 {
    border-top: 1px solid #2f323b;
  }

  .s2 .card {
    background-color: #13151b;
    border: 2px solid #20232c;
    border-radius: 16px;
  }

  .s2 .card p {
    color: #8694b2;
    font-size: 20px;
  }

  .card-noticia {
    background: linear-gradient(
      100.14deg,
      rgba(107, 117, 182, 0.15) 4.13%,
      rgba(39, 48, 145, 0.0045) 94.28%
    );
    background-color: #13151b;
    border: 2px solid #20232c;
    border-radius: 16px;
  }

  .card-noticia .img {
    border: 5px solid #20232c;
    border-radius: 16px;
  }

  .card-noticia .img-content {
    background-position: center center;
    background-size: cover;
    height: 300px;
  }

  .card-noticia .icon-sm {
    height: 15px;
    margin-bottom: 4px;
  }

  .card-noticia .media {
    border-bottom: solid 3px #20232c;
  }

  @media (max-width: 767px) {
    .s1 .btn,
    .s2 .btn,
    .s3 .btn {
      display: block;
    }
  }

  @media (min-width: 768px) {
    .s2 .card {
      height: 500px;
    }
  }

  @media (min-width: 1200px) {
    .container-home1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 39%;
      z-index: 1;
    }
  }
</style>
