import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

function requiresLoggedOut (to, from, next) {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }
  store.dispatch('addMessage', 'Você já está logado. Para realizar está ação é necessário deslogar-se primeiro.')
  next({ name: 'Home' })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/noticias/',
  //   name: 'News',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/news/News.vue')
  // },
  // {
  //   path: '/noticias/:id/',
  //   name: 'News_id',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/news/_id.vue')
  // },
  {
    path: '/login/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/cadastro/',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/contato/',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  // {
  //   path: '/como-funciona/',
  //   name: 'Howitworks',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Howitworks.vue')
  // },
  {
    path: '/redefinir-senha/',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/confirmar-senha/',
    name: 'Confirm Password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ConfirmPassword.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/reenviar-email/',
    name: 'Resend Email',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResendEmail.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/validar-email/',
    name: 'Email Validate',
    component: () => import(/* webpackChunkName: "about" */ '../views/ValidateEmail.vue'),
    beforeEnter: requiresLoggedOut
  },
  {
    path: '/perfil/',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/alterar-perfil/',
    name: 'Change Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangeProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/fantasy/:id?',
    name: 'League Fantasy',
    component: () => import(/* webpackChunkName: "about" */ '../views/fantasy/Index.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/politica/',
    name: 'Política de Privacidade',
    component: () => import(/* webpackChunkName: "about" */ '../views/Politica.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/termos/',
    name: 'Termos de Uso',
    component: () => import(/* webpackChunkName: "about" */ '../views/Termos.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/bolao/',
    name: 'Bolão',
    component: () => import(/* webpackChunkName: "about" */ '../views/Bolao.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ '../views/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
