<template>
  <app-modal
    title="Respeitamos a sua privacidade"
    id="cookie-policy-settings"
    size="xl"
    scrollable
    :hide-footer="false"
  >
    <template #title>
      <h6 class="ff-chaney mb-0">
        <b-img
          :src="require('@/assets/icons/broken/lock.svg')"
          fluid
          alt=""
          class="icon-green icon-sm"
        ></b-img>
        Respeitamos a sua privacidade
      </h6>
    </template>
    <template #content>
      <b-row>
        <b class="ml-3">Última atualização: 01/09/2021</b>
      </b-row>
      <b-row>
        <p class="mx-3 my-2">
          O Hintclub preza pela sua privacidade e garante a proteção de seus
          dados pessoais! Destacamos que utilizamos cookies para melhorar sua
          experiência no site, conforme a nossa Política de Privacidade. Fique à
          vontade para customizar os cookies que não são estritamente
          necessários.
        </p>
      </b-row>
      <b-row class="border-bottom-row my-2 py-2">
        <b-col cols="12" sm="6">
          <b class="align-middle">Classes de cookies</b>
        </b-col>
        <b-col cols="12" sm="6" class="text-left text-sm-right">
          <b-form-checkbox
            v-model="selectAll"
            @change="toggleCheckboxes"
            size="lg"
          >
            {{ selectAllText }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <AppCookiePolicyCategory
        :category="category"
        v-for="(category, index) in categories"
        v-model="category.value"
        :key="index"
      />
    </template>
    <template #footer>
      <b-button class="btn-default btn-3" @click="acceptCookies">Aceitar</b-button>
    </template>
  </app-modal>
</template>

<script>
export default {
  name: 'AppCookiePolicy',
  components: {
    AppModal: () => import('@/components/AppModal'),
    AppCookiePolicyCategory: () =>
      import('@/components/modal/AppCookiePolicyCategory.vue')
  },
  data: () => ({
    categories: [
      {
        title: 'Estritamente Necessário',
        required: true,
        value: true,
        description:
          'Esses cookies são indispensáveis para o funcionamento do site e não podem ser desligados em nossos sistemas por usarem recursos essenciais. Normalmente, eles são definidos apenas em resposta a ações feitas por você que equivalem a uma solicitação de serviços, como definir suas preferências de privacidade, fazer login ou preencher formulários. Você pode configurar seu navegador para bloquear ou alertá-lo sobre esses cookies, mas algumas partes do site não funcionarão. Esses cookies não armazenam qualquer informação pessoalmente identificável.',
        cookies: [
          {
            name: 'access_token',
            expiration: 'Sessão / 1 Dia',
            domain: 'hintclub.com.br',
            company: 'Hintclub',
            reason: 'Autorização',
            description:
              'Cookie de autenticação, possui tempo de expiração de 1 dia caso a opção "Manter-me logado" tenha sido marcada durante o login.'
          },
          {
            name: 'refresh_token',
            expiration: 'Sessão / 14 Dia',
            domain: 'hintclub.com.br',
            company: 'Hintclub',
            reason: 'Autorização',
            description:
              'Cookie de renovação autómatica da autenticação, possui tempo de expiração de 14 dia caso a opção "Manter-me logado" tenha sido marcada durante o login.'
          },
          {
            name: 'validation_token',
            expiration: 'Sessão / 14 Dia',
            domain: 'hintclub.com.br',
            company: 'Hintclub',
            reason: 'Autorização',
            description:
              'Cookie de validação da opção "Manter-me logado" da autenticação, possui tempo de expiração de 1 dia caso a opção "Manter-me logado" tenha sido marcada durante o login.'
          }
        ]
      },
      {
        title: 'Funcionais',
        required: false,
        value: true,
        description:
          'Esses cookies permitem que o site proporcione a você funcionalidades e personalização aprimoradas. Podem ser definidos por nós ou por provedores terceiros cujos serviços adicionamos às nossas páginas. Se você não permitir esses cookies, alguns ou todos esses serviços podem não funcionar corretamente.',
        cookies: [
          {
            name: '_GRECAPTCHA',
            expiration: '180 dias',
            domain: 'google.com',
            company: 'Google',
            reason: 'Funcional',
            description:
              'O reCAPTCHA define um cookie necessário (_GRECAPTCHA) quando executado para a finalidade de fornecer sua análise de risco.'
          }
        ]
      },
      {
        title: 'Desempenho',
        required: false,
        value: true,
        description:
          'Cookies de desempenho são cookies usados especificamente para coletar dados sobre como os visitantes usam o site, incluindo as páginas visitadas com mais frequência e as mensagens de erro recebidas durante a navegação. Esses cookies monitoram apenas o desempenho do site, à medida que o usuário interage com ele.',
        cookies: [
          {
            name: '_ga',
            expiration: '2 anos',
            domain: 'hintclub.com.br',
            company: 'Google',
            reason: 'Estatística',
            description:
              'Usado para distinguir usuários por Google Analytics (cookies analíticos de terceiros: google analytics para fins estatísticos).Para maximizar a privacidade dos visitantes, utilizamos o recurso "mascaramento de IP", que impõe ao sistema o escurecimento dos últimos 8 bits do endereço IP do visitante antes que qualquer tipo de processamento seja realizado. Tipo de dados processados: Endereço IP "Mascarado", Navegador, Dispositivo, Sistema Operacional, Tamanho de tela, Tempo de Carregamento, Quantidade de Dados Tráfegados . Objetivo analisado: recolher informação, de forma agregada, sobre o número de utilizadores e a forma como visitam o nosso site, para tratamento de estatísticas.'
          },
          {
            name: '_ga_*',
            expiration: '2 anos',
            domain: 'hintclub.com.br',
            company: 'Google',
            reason: 'Estatística',
            description: 'Usado para distinguir usuários por Google Analytics.'
          },
          {
            name: '_gat_*',
            expiration: '1 minuto',
            domain: 'hintclub.com.br',
            company: 'Google',
            reason: 'Estatística',
            description: 'Usado para limitar solicitações ao Google Analytics.'
          }
        ]
      }
    ],
    selectAll: true,
    consent: localStorage.getItem('cookieConsent')
      ? JSON.parse(localStorage.getItem('cookieConsent'))
      : null
  }),
  mounted () {
    if (this.consent) {
      this.categories[1].value = this.consent.functional
      this.categories[2].value = this.consent.performance
    }
  },
  computed: {
    selectAllText () {
      return this.selectAll ? 'Desselecionar tudo' : 'Selecionar tudo'
    }
  },
  methods: {
    toggleCheckboxes (selected) {
      if (selected) {
        this.categories.forEach(category => {
          category.value = true
        })
      } else {
        this.categories.forEach(category => {
          category.value = false
        })
      }
    },
    acceptCookies () {
      window.dispatchEvent(
        new CustomEvent('cookie-consent-localstorage-changed', {
          detail: {
            storage: {
              lastModified: null,
              necessary: true,
              functional: this.categories[1].value,
              performance: this.categories[2].value
            }
          }
        })
      )
      this.$bvModal.hide('cookie-policy-settings')
    }
  }
}
</script>

<style scoped>
.border-bottom-row {
  border-bottom: 1px solid #e0e0e0;
}
.custom-checkbox.b-custom-control-lg, .input-group-lg .custom-checkbox{
  font-size: 1rem;
}
.modal-body a{
  color: #8694b2!important;
}
.text-success {
  color: #8694b2!important;
}
</style>
