// initial state
const state = () => ({
  badges: []
})

// getters
const getters = {
  hasBadges: state => state.badges.length > 0,
  getBadges: state => state.badges
}

// actions
const actions = {
  showBadges ({ commit }, badges) {
    commit('setBadges', badges)
    badges.forEach(badge => {
      new Image().src = badge.image
    })
  },
  clearBadges ({ commit }) {
    commit('setBadges', [])
  }
}

// mutations
const mutations = {
  setBadges (state, badges) {
    state.badges = badges
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
