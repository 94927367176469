import { service } from '../boot/axios'
import { statusResponse } from './responseService'
export default class Base {
  constructor (api) {
    this.api = api
    this.service = service
  }

  list = (query = '') => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${query}`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'list'))
      })
    })
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  create = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(this.api, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'create', 'item'))
      })
    })
  }

  update = ($data) => {
    return new Promise((resolve, reject) => {
      const id = $data.user_id ? $data.user_id : $data.id
      service.put(`${this.api}${id}/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'update', 'item'))
      })
    })
  }

  remove = (id) => {
    return new Promise((resolve, reject) => {
      service.delete(`${this.api}${id}/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'remove', 'item'))
      })
    })
  }

  getItem = async (url) => {
    const entry = await service.cache.getItem(`${service.defaults.baseURL}${url}`)
    if (entry && Date.now() > entry.expires) {
      await this.clear(url)
      return null
    }
    return entry ? entry.data.data : null
  }

  createItem = async (url, data) => {
    const entry = {
      expires: Date.now() + 900000, // 15 min
      data: { data: data }
    }
    await service.cache.setItem(`${service.defaults.baseURL}${url}`, entry)
  }

  updateItem = async (url, data) => {
    const entry = await service.cache.getItem(`${service.defaults.baseURL}${url}`)
    if (entry) {
      entry.data.data = data
      await service.cache.setItem(`${service.defaults.baseURL}${url}`, entry)
      return true
    }
    return false
  }

  clear = async (url) => {
    await service.cache.removeItem(`${service.defaults.baseURL}${url}`)
  }
}
