import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
export default {
  methods: {
    setNotifyDanger (message) {
      const vm = new Vue()
      // Use a shorter name for `this.$createElement`
      const h = vm.$createElement
      // Create a ID with a incremented count
      const id = `my-toast-${uuidv4()}`

      // Create the custom close button
      const $closeButton = h(
        'b-button-close',
        {
          on: { click: () => vm.$bvToast.hide(id) }
        }
      )
      vm.$bvToast.toast([message, $closeButton], {
        id: id,
        toaster: 'b-toaster-top-full',
        solid: true,
        appendToast: true,
        noCloseButton: true,
        variant: 'danger'
      })
    },

    setNotifySuccess (message) {
      const vm = new Vue()
      // Use a shorter name for `this.$createElement`
      const h = vm.$createElement
      // Create a ID with a incremented count
      const id = `my-toast-${uuidv4()}`

      // Create the custom close button
      const $closeButton = h(
        'b-button-close',
        {
          on: { click: () => vm.$bvToast.hide(id) }
        }
      )
      vm.$bvToast.toast([message, $closeButton], {
        id: id,
        toaster: 'b-toaster-top-full',
        solid: true,
        appendToast: true,
        noCloseButton: true,
        variant: 'success'
      })
    },

    setNotifyInfo (message) {
      const vm = new Vue()
      // Use a shorter name for `this.$createElement`
      const h = vm.$createElement
      // Create a ID with a incremented count
      const id = `my-toast-${uuidv4()}`

      // Create the custom close button
      const $closeButton = h(
        'b-button-close',
        {
          on: { click: () => vm.$bvToast.hide(id) }
        }
      )
      vm.$bvToast.toast([message, $closeButton], {
        id: id,
        toaster: 'b-toaster-top-full',
        solid: true,
        appendToast: true,
        noCloseButton: true,
        variant: 'primary'
      })
    }

  }
}
