import axios from 'axios'

const statusResponse = (obj, type, description) => {
  let response = obj.response
  if (obj instanceof axios.Cancel || obj instanceof axios.CancelToken) {
    response = { status: 429, headers: { 'retry-after': obj.message }, data: {} }
  }
  return !navigator.onLine
    ? new Error('Sem acesso à internet, por favor, tente mais tarde')
    : !response
      ? new Error('Servidor indisponivel')
      : verifyStatusResponse(response, type, description)
}
const verifyStatusResponse = (response, type, description) => {
  const data = response.data
  const status = response.status
  const typesStatus = {
    400: { error: verifyErrorsData(data) },
    401: { error: new Error('Sessão expirada, por favor , logue novamente') },
    403: { error: new Error('Você não tem permissão para executar esta ação') },
    404: { error: new Error('Endereço de acesso não encontrado') },
    406: { error: new Error(!data.detail ? 'Não Aceitável' : data.detail) },
    429: { error: new Error(`Limite de solicitações alcançado. Tente novamente em ${response.headers['retry-after']} segundos.`) },
    500: { error: verifyTypeAction(type, description) },
    503: { error: new Error(!data.detail ? 'Não foi possivel se connectar ao servidor' : data.detail) }
  }
  const action = !Object.keys(typesStatus).includes(`${status}`) ? { error: new Error('Falha ao conectar com o servidor, isso pode ser causado devido ao uso de VPN, tente desativa-lá. Caso o problema persista, favor entrar em contato conosco pelo email contato@hintclub.com.br') } : typesStatus[status]
  action.error.name = status
  return action.error
}

const verifyErrorsData = data => {
  return data.fields === undefined
    ? new Error(!data.detail ? JSON.stringify(data) : data.detail)
    : new Error(!data.fields ? '' : JSON.stringify(data.fields))
}
const verifyTypeAction = (action, description) => {
  action = !action ? 'action' : action
  const typesAction = {
    list: {
      error: new Error('Não foi possível realizar a listagem, tente mais tarde')
    },
    get: {
      error: new Error(
        `Não foi possível realizar a consulta de ${description}, tente mais tarde`
      )
    },
    create: {
      error: new Error('Não foi possível realizar o cadastro, tente mais tarde')
    },
    update: {
      error: new Error('Não foi possível realizar a edição, tente mais tarde')
    },
    remove: {
      error: new Error('Não foi possível realizar a exclusão, tente mais tarde')
    },
    action: {
      error: new Error('Não foi possível realizar a ação, tente mais tarde')
    }
  }
  return typesAction[action].error
}
export { statusResponse }
