<template>
  <div id="app">
    <b-modal id="bv-modal-announcement" hide-footer>
      <template #modal-title class="text-left"
        ><h6 class="ff-chaney mb-0">
          <img
            src="/img/information.73626e9f.svg"
            alt=""
            class="icon-green icon-sm img-fluid"
          />
          ANÚNCIO
        </h6></template
      >
      <div class="d-block text-center">
        <h5 class="text-center text-3">
          Salve managers de plantão! Nesse segundo split o CARTOLOL vai fazer
          uma pausa. Estamos organizando tudo para oferecer o melhor fantasy
          game do nosso League of Legends. Nos vemos em breve!
        </h5>
      </div>
    </b-modal>
    <app-navbar />
    <router-view class="page-content" />
    <app-footer />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar'
import AppFooter from '@/components/AppFooter'
export default {
  name: 'App',
  metaInfo: {
    meta: [
      {
        lang: 'pt-BR',
        title: 'HintClub',
        descrition:
          'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords:
          'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'HintClub',
        type: 'website',
        robots: 'index, follow'
      }
    ]
  },
  components: {
    AppFooter,
    AppNavbar
  },
  mounted () {
    this.$bvModal.show('bv-modal-announcement')
  }
}
</script>

<style lang="scss">
@import './assets/css/app.scss';
</style>
