import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueSmoothScroll from 'vue2-smooth-scroll'
import Rollbar from 'rollbar'
import VueGtag from 'vue-gtag'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

Vue.use(VueTheMask)
Vue.use(money, { decimal: ',', thousands: '.', prefix: '', precision: 2 })
Vue.use(VueMeta)
Vue.use(VueSmoothScroll, { duration: 400, updateHistory: false })
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCountdownTimer)
Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_ID || '',
    params: {
      anonymize_ip: true
    }
  },
  appName: 'CartoLOL',
  enabled: false,
  pageTrackerScreenviewEnabled: true
}, router)

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN || '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: (process.env.NODE_ENV === 'production'), // returns true if 'production'
  payload: {
    environment: process.env.APP_ENV
  }
})

Vue.prototype.$MEDIA_URL = process.env.VUE_APP_MEDIA_URL || '/'

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err)
  throw err // rethrow
}

Vue.use(Vuelidate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
